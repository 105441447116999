// Prevent default browser drag behavior on images
document.ondragstart = function() { return false; };

document.addEventListener('DOMContentLoaded', function() {
    if (!('ontouchstart' in window)) {
        var warningMessage = '<div style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100vh; text-align: center;"><p>This website can only be used on mobile.</p><p>Please switch to a touch device.</p></div>';

        // Select the elements you want to hide when not on a mobile device.
        var elementsToHide = document.querySelectorAll('.funky-button, .instructions, #countdown, #app, #image-container, #visualizer');

        // Hide those elements.
        for (var i = 0; i < elementsToHide.length; i++) {
          elementsToHide[i].style.display = 'none';
        }

        // Add the warning message to the body, without removing the footer.
        document.body.innerHTML += warningMessage;
        return;
      }
    var music = document.getElementById('music');
    var getFunky = document.getElementById('getFunky');
    var countdown = document.getElementById('countdown');
    var carousel = document.getElementById('carousel');
    var birdImg = document.getElementById('birdImg');
    var imageContainer = document.getElementById('image-container');
    var instructions = document.querySelector('.instructions');

    getFunky.addEventListener('click', function() {
        var counter = 3;
        countdown.textContent = counter;
        countdown.classList.remove('hide');
        getFunky.classList.add('hide');

        var intervalId = setInterval(function() {
            counter--;
            countdown.textContent = counter;
            if (counter === 0) {
                clearInterval(intervalId);
                countdown.classList.add('hide');
                carousel.classList.remove('hide');
                instructions.classList.add('hide');
                setupCarousel();
                document.title = "Get Funky!";
                music.play();
            }
        }, 1000);
    });

    var currentDeltaX = 0; // stores current image position

    function setupCarousel() {
        var hammertime = new Hammer(carousel);
        hammertime.get('pan').set({ direction: Hammer.DIRECTION_ALL });
        var maxDeltaX = imageContainer.offsetWidth / 2;

        hammertime.on('panstart', function(ev) {
            birdImg.style.transition = 'none';
            birdImg.style.transform = 'translateX(' + currentDeltaX + 'px)';
        });

        hammertime.on('panmove', function(ev) {
            var newDeltaX = ev.deltaX + currentDeltaX;

            if (newDeltaX > maxDeltaX) {
                newDeltaX = maxDeltaX;
            } else if (newDeltaX < -maxDeltaX) {
                newDeltaX = -maxDeltaX;
            }

            birdImg.style.transform = 'translateX(' + newDeltaX + 'px)';
        });

        hammertime.on('panend', function(ev) {
            birdImg.style.transition = 'transform 0.2s';
            if ((ev.deltaX > 0 && ev.deltaX + currentDeltaX > 0) || (ev.deltaX < 0 && ev.deltaX + currentDeltaX > 0)) {
                currentDeltaX = maxDeltaX;
            } else {
                currentDeltaX = -maxDeltaX;
            }
            birdImg.style.transform = 'translateX(' + currentDeltaX + 'px)';
        });
        
        
    }
});
